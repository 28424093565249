<template>
  <div>
    <button type="submit" class="btn btn-primary rounded-pill btn-acceder btn-acceder-home">{{literal}}</button>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'LoginButton',
  props: {
    literal: String,
  },
  data() {
    return {
      signin: 'https://microsoft.com',
    };
  },
  computed: {
    ...mapState([
      'msalConfig',
      'enviroments'
    ]),
  },
  mounted() {
  }
};
</script>
<style scoped>
</style>
