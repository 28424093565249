<template>
    <div v-if="step.firstStep || steps === 'false'" class="cabecera-background" :class="{'home': steps === 'false'}">
        <div class="container">
            <div class="row">
                <div class="cabecera col">
                    <!--<p class="cabecera-entry d-block d-md-none d-lg-none d-xl-none">{{$t('header.subtitleMobile')}}</p>-->
                    <h1 v-if="steps === 'false'" class="cabecera-title" v-html="$t('header.titleHome')"></h1>
                    <h1 v-if="steps === 'true'" class="cabecera-title">{{$t('header.titleSteps')}}</h1>
                    <div class="divider-wrap mx-auto">
                        <div class="divider-little"></div>
                    </div>
                    <div class="cabecera-subtitle row">
                        <!--
        <p class="col-12 col-md-10 col-lg-10">The Global College ofrece una educación pre-universitaria e innovadora en España, anclada en el Programa de Diploma de dos años del Bachillerato Internacional* y en la historia y valores de IE University.</p>
        <p class="col-12 col-md-10 col-lg-10 d-none d-md-block">Preparamos a estudiantes de todo el mundo para el acceso a universidades de prestigio, a través de un currículum personalizado y atractivo, excelente tutorización y asesoramiento para la universidad, y la adquisición de las habilidades necesarias para competir en un mundo en constante cambio tecnológico.</p>
    -->
                    </div>
                </div>
                <div class="scroller justify-content-center col-12">
                    <div v-if="steps === 'false'" class="no-margin text-center">
                        <LoginButton :literal="$t('header.button')" @click="launchSignIn()" />
                    </div>
                    <a href="#formulario" class="col-12 text-center">
                        <img src="@/assets/img/scrooll.png" alt="ir a formulario" class="d-lg-none d-xl-none mx-auto">
                        <img src="@/assets/img/scrooll_lg.png" alt="ir a formulario" class="d-none d-lg-block d-xl-block mx-auto">
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div v-if="steps === 'true' && step.firstStep" class="container">
        <div class="row">
            <div class="comun-entry comun-entry-full">
                <div class="form-title text-center col-lg-12">
                    <h2 class="form-subtitle">{{$t('steps.title')}}</h2>
                    <div class="divider-wrap col-12 ">
                        <div class="divider-middle mx-auto"></div>
                    </div>
                    <div class="form-text offset-lg-1 col-lg-10">
                        <p class="form-p">
                            {{$t('steps.subtitle')}}
                        </p>
                        <p v-if="stepNumb === '12332'" class="title_doc" v-html="$t('steps.subtitleS1',{env_url:enviroments.urlDOM})"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="!step.firstStep && steps === 'true'" class="cabecera-continue">
        <div class="container">
            <div class="row">
                <div class="comun-entry comun-entry-full">
                    <div class="form-title text-center col-xl-12">
                        <h2 class="form-subtitle">{{$t('header.headerTitle'+stepNumb)}}</h2>
                        <div class="divider-wrap col-12 ">
                            <div class="divider-middle mx-auto"></div>
                        </div>
                        <div class="form-text offset-lg-1 col-lg-10">
                            <p class="form-p">
                                {{$t('header.headerText'+stepNumb)}}
                            </p>
                            <p v-if="stepNumb === '12332'" class="title_doc2" v-html="$t('steps.subtitleS1',{env_url:enviroments.urlDOM})"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { mapState } from 'vuex'
    import LoginButton from '@/components/LoginButton.vue';


    export default {
        name: 'Header',
        components: {
            LoginButton
        },
        props: {
            steps: Boolean,
            stepNumb: {
                type: String,
                default: "1"
            }
        },
        data() {
            return {
            };
        },
        computed: {
            ...mapState([
                'step',
                'enviroments'
            ]),
        },
        created() {
        },
        mounted() {
            if (this.stepNumb === "6") { this.step.firstStep = false }
        },
        methods: {
            launchSignIn() {
                this.$emit('launchLogin', true);
            }
        }
    };
</script>
<style scoped>
</style>

