<template>
  <div v-if="showModal" id="modalhome" style="display:block;" class="modal fade show">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <h5 class="modal-title">{{$t('home.popupContent.title')}}</h5>
          <div class="divider-wrap col-12">
            <div class="divider-middle mx-auto"></div>
          </div>
          <p class="modal-text mx-auto" v-html="this.popupText"></p>
          <div class="col text-center btn-modal-expired">
            <button @click="closeModalHome()" type="button" class="btn btn-primary rounded-pill btn-acceder btn-acceder-home">{{$t('home.popupContent.button')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'ModalHome',
  components: {
  },
  props: {
    showModalHome: Boolean
  },
  data() {
    return {
      showModal: false,
      popupText: '',
      popupTitle: ''
    }
  },
  computed: {
    ...mapState([
      'msalConfig',
      'enviroments',
    ]),
  },
  watch: {
  },
  mounted() {
//    this.showModal = true;
    this.getModalInfo();
    if(this.showModal) document.body.style.overflowY = "hidden";
  },
  methods: {
    getModalInfo()
    {
        let langParam = "&idioma=en";
        if (this.$i18n.locale == 'es')
        {langParam = "&idioma=es";}

        this.axios.get(`${this.enviroments.urlDev}/ProductConfiguration?token=null${langParam}`).then(response => {
          if (response.data.status === 1) {
            this.serviceError = true;
            this.serviceErrorMsg =  response.data.message;
          } else if(response.data.status === 2) {
            this.expireToken = true;
          }
          else if(response.data.status === 0){
            this.popupTitle = response.data.popupTitle;
            this.popupText = response.data.popupText;
            this.popupEnable = response.data.popupEnabled;

            if(this.popupEnable && this.popupText!=null && this.popupText!="")
            {this.showModal = true;}

          }
        })
        .catch( error => {
          console.log(error);
          this.serviceError = true;
          this.serviceErrorMsg =  `ERROR:: BASIC INFORMATION get Countries: ${error}`;
        });

    },
    closeModalHome() {
      this.showModal = false;
      document.body.style.overflowY = "auto";
    }
  }
}
</script>

<style>
.modal {
  display: inherit;
}
</style>