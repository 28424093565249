<template>
    <div>
        <HeaderBar isHome="true" ref="headBarObj" />
        <Header steps="false" stepNumb="0" @launch-login="$refs.headBarObj.SignIn()" />
        <div class="container">
            <div class="row">
                <div class="home-comun-entry">
                    <div class="home-title text-center col-lg-12">
                        <!--<h2 class="home-subtitle">{{$t('home.topContent.title')}}</h2>-->
                        <div class="divider-wrap col-12 ">
                            <div class="divider-middle mx-auto"></div>
                        </div>
                        <div class="home-text offset-lg-1 col-lg-10 text-center">
                            <p class="home-p force-left" v-html="$t('home.topContent.subtitle',{env_url:this.enviroments.urlDOM})">
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="background-grey">
            <div class="container tablet-desktop-no-carousel d-none d-md-block" id="">
                <div class="row">
                    <div class="col-md-12 col-lg-4">
                        <div class="card diapos active mx-auto">
                            <img class="card-img-top" src="@/assets/img/Typing.jpg" alt="Card image cap">
                            <div class="card-body">
                                <h3 class="card-title">{{$t('home.topContent.postOne.title')}}</h3>
                                <p class="card-text">{{$t('home.topContent.postOne.description')}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-4">
                        <div class="card diapos mx-auto">
                            <img class="card-img-top" src="@/assets/img/Planes.jpg" alt="Card image cap">
                            <div class="card-body">
                                <h3 class="card-title">{{$t('home.topContent.postTwo.title')}}</h3>
                                <p class="card-text">{{$t('home.topContent.postTwo.description')}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-4">
                        <div class="card diapos mx-auto">
                            <img class="card-img-top" src="@/assets/img/People.jpg" alt="Card image cap">
                            <div class="card-body">
                                <h3 class="card-title">{{$t('home.topContent.postThree.title')}}</h3>
                                <p class="card-text">{{$t('home.topContent.postThree.description')}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mobile-si-carousel text-center carousel slide d-block d-sm-block d-md-none carousel_1" data-ride="carousel" id="carousel_1">
                <ol class="carousel-indicators d-md-none">
                    <li data-target="#carouselIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselIndicators" data-slide-to="2"></li>
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item card diapos active">
                        <img class="card-img-top" src="@/assets/img/Typing.jpg" alt="Card image cap">
                        <div class="card-body">
                            <h3 class="card-title">{{$t('home.topContent.postOne.title')}}</h3>
                            <p class="card-text">{{$t('home.topContent.postOne.description')}}</p>
                        </div>
                    </div>
                    <div class="carousel-item card diapos ">
                        <img class="card-img-top" src="@/assets/img/Planes.jpg" alt="Card image cap">
                        <div class="card-body">
                            <h3 class="card-title">{{$t('home.topContent.postTwo.title')}}</h3>
                            <p class="card-text">{{$t('home.topContent.postTwo.description')}}</p>
                        </div>
                    </div>
                    <div class="carousel-item card diapos">
                        <img class="card-img-top" src="@/assets/img/People.jpg" alt="Card image cap">
                        <div class="card-body">
                            <h3 class="card-title">{{$t('home.topContent.postThree.title')}}</h3>
                            <p class="card-text">{{$t('home.topContent.postThree.description')}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="main-carousel">
          <div class="col-12 col-lg-4 carousel-cell">
            <div class="card diapos active mx-auto">
              <img class="card-img-top" src="@/assets/img/Typing.jpg" alt="Card image cap">
              <div class="card-body">
              <h3 class="card-title">DISEÑO INNOVADOR  </h3>
              <p class="card-text">The Global College cuenta con un campus excepcional situado en el madrileño Barrio de Salamanca, en la calle Castellón de la Plana, 8. Sus instalaciones, que en la actualidad albergan programas de máster de IE Business School.</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4 carousel-cell">
            <div class="card diapos mx-auto">
              <img class="card-img-top" src="@/assets/img/Planes.jpg" alt="Card image cap">
              <div class="card-body">
              <h3 class="card-title">UN CAMPUS ÚNICO   </h3>
              <p class="card-text">The Global College cuenta con un campus excepcional situado en el madrileño Barrio de Salamanca, en la calle Castellón de la Plana, 8. Sus instalaciones, que en la actualidad albergan programas de máster de IE Business School, se convertirán en nuestra sede gracias a la alianza que mantenemos con IE University. </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4 carousel-cell">
            <div class="card diapos mx-auto">
              <img class="card-img-top" src="@/assets/img/People.jpg" alt="Card image cap">
              <div class="card-body">
              <h3 class="card-title">EDIFICIO SOSTENIBLE </h3>
              <p class="card-text">The Global College cuenta con un campus excepcional situado en el madrileño Barrio de Salamanca, en la calle Castellón de la Plana, 8. Sus instalaciones, que en la actualidad albergan programas de máster de IE Business School, se convertirán. </p>
              </div>
            </div>
          </div>
        </div> -->

        <div class="container">
            <div class="home-comun-entry">
                <div class="row">
                    <div class="home-title text-center col-lg-12">
                        <!--<h2 class="home-subtitle">{{$t('home.bottomContent.title')}}</h2>-->
                        <div class="divider-wrap col-12 ">
                            <div class="divider-middle mx-auto"></div>
                        </div>
                        <div class="home-text offset-lg-1 col-lg-10 text-center">
                            <p class="home-p" v-html="$t('home.bottomContent.subtitle')">
                            </p>
                        </div>
                    </div>
                </div>


                <div class="col text-center btn-align-footer">
                    <LoginButton :literal="$t('header.button')" @click="$refs.headBarObj.SignIn()" />
                </div>
            </div>
        </div>

        <Footer />
    </div>
    <ModalHome/>

</template>

<script>

    import HeaderBar from '@/components/HeaderBar.vue'
    import Header from '@/components/Header.vue'
    import Footer from '@/components/Footer.vue'
    import LoginButton from '@/components/LoginButton.vue';
    import ModalHome from '@/components/ModalHome.vue'
    import { useRoute } from 'vue-router'
    import { mapState } from 'vuex'



    export default {
        name: 'Home',
        components: {
            HeaderBar,
            Header,
            Footer,
            LoginButton,
            ModalHome
        },
        mounted() {
            window.scrollTo(0, 0);

            const route = useRoute();
            this.bypassSign = route.params.param === 'signin' ? true : false;
            this.bypassChild = route.params.param === 'child' ? true : false;

            if (this.bypassSign) { this.$refs.headBarObj.SignIn() }
            if (this.bypassChild) { this.$refs.headBarObj.getFirstConsent() }


        },
        computed: {
            ...mapState([
                'enviroments'
            ]),
        },
        data() {
            return {
                bypassSign: false,
                bypassChild: false,
            }
        },
    }
</script>